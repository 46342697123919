// Development time offset in hours (change this value to adjust time)
// Examples:
// 0 = current time
// 1 = add 1 hour
// -1 = subtract 1 hour
// 12 = add 12 hours
export const DEV_HOURS = 0;

// Get current time with offset
export const getDevTime = () => {
  const now = new Date();
  return new Date(now.getTime() + DEV_HOURS * 60 * 60 * 1000);
};

// Set time offset in hours
export const setDevHours = (hours) => {
  devTimeOffset = hours * 60 * 60 * 1000;
};
