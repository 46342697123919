import { useState, useEffect } from "react";
import { Text } from "@chakra-ui/react";
import { getDevTime } from '../utils/devTime';

const EventCountdown = ({ start, end, entriesTimer }) => {
  const [timeUntilEnd, setTimeUntilEnd] = useState("");

  useEffect(() => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const updateCountdown = () => {
      const now = getDevTime();
      const hour = now.getHours();
      const endDiff = endDate.getTime() + 2 * 60 * 60 * 1000 - now.getTime();

      // Show competition results timer between 11 AM and 7 PM
      if (hour >= 11 && hour < 19) {
        if (endDiff > 0) {
          const hours = Math.floor((endDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((endDiff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((endDiff % (1000 * 60)) / 1000);

          let timeString = '';
          if (hours > 0) timeString += `${parseInt(hours)}h `;
          if (minutes > 0) timeString += `${parseInt(minutes)}m `;
          timeString += `${parseInt(seconds)}s`;

          setTimeUntilEnd(timeString);
        } else {
          setTimeUntilEnd("");
        }
      } else {
        setTimeUntilEnd(""); // Clear competition timer to allow entries timer to show
      }
    };

    updateCountdown();
    const timer = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [start, end]);

  const now = getDevTime();
  const hour = now.getHours();
  const showEntriesTimer = hour < 11 || hour >= 19;

  return (
    <>
      <Text marginTop={1}>
        {showEntriesTimer && entriesTimer ? (
          `Entries Close in ${entriesTimer}`
        ) : timeUntilEnd ? (
          `Competition Results in ${timeUntilEnd}`
        ) : null}
      </Text>
    </>
  );
};

export default EventCountdown;
